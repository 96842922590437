import request from "@/utils/request";

//查询所有的标签组类别数据
export function getGroupLabel() {
  return request({
    url: "/system/label/getGroupLabel",
    method: "get"
  });
}

//标签管理一览
export function labelPage(data) {
  return request({
    url: "/system/label/labelPage",
    method: "post",
    data
  });
}

//新增标签组
export function addlabel(data) {
  return request({
    url: "/system/label/insertLabel",
    method: "post",
    data
  });
}
//编辑标签组
export function updateLabel(data) {
  return request({
    url: "/system/label/updateLabel",
    method: "post",
    data
  });
}
//标签组数据回显（标签组基础数据+一级标签）
export function selectLabelDetail(query) {
  return request({
    url: "/system/label/selectLabelDetail",
    method: "get",
    params: query
  });
}
//根据上级标签的id查询出下级标签集合
export function getLabelList(query) {
  return request({
    url: "/system/label/getLabelList",
    method: "get",
    params: query
  });
}

//根据标签组名称模糊查询所有标签组
export function getAssociateGroupLabel(query) {
  return request({
    url: "/system/label/getAssociateGroupLabel",
    method: "get",
    params: query
  });
}

//
export function getAssociateLabel(query) {
  return request({
    url: "/system/label/getAssociateLabel",
    method: "get",
    params: query
  });
}

//查询标签管理 应用模块

export function menuTypeOptionsApi(query) {
  return request({
    url: "/system/code/getCodeByStatus?code=menu_type ",
    method: "get",
    params: query
  });
}
