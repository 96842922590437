<template>
  <div>
    <el-form ref="form" :model="queryInfo" label-width="120px">
      <el-card>
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="标签组层级:" prop="">
              <el-radio-group
                v-model="queryInfo.groupLabelLevel"
                @change="search"
              >
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button label="1">一级标签 </el-radio-button>
                <el-radio-button label="2">二级标签 </el-radio-button>
                <el-radio-button label="3">三级标签 </el-radio-button>
                <el-radio-button label="4">四级标签 </el-radio-button>
                <el-radio-button label="5">五级标签 </el-radio-button>
                <el-radio-button label="6">六级标签 </el-radio-button>
                <el-radio-button label="7">七级标签 </el-radio-button>
                <el-radio-button label="8">八级标签 </el-radio-button>
                <el-radio-button label="9">九级标签 </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="标签组层级:" prop="">
              <el-radio-group
                v-model="queryInfo.groupLabelType"
                @change="search"
              >
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button
                  v-for="(item, index) in grouplabel"
                  :key="index"
                  :label="item.labeltype"
                  >{{ item.labeltype }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-card>
    </el-form>
    <el-card style="margin-top: 20px">
      <div class="input-with">
        <el-button type="primary" @click="addlabel"> + 新增</el-button>
        <el-input
          placeholder="请输入标签组名称，标签组说明"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        @sort-change="changeSort"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column
          label="编号"
          align="center"
          prop="id"
        ></el-table-column>
        <el-table-column label="标签名称" prop="groupLabelName" align="center">
          <template v-slot="{ row }">
            <span>{{ row.groupLabelName || "-" }}</span>
          </template></el-table-column
        >
        <el-table-column
          label="标签组前缀"
          prop="groupLabelPrefix"
          align="center"
        >
          <template v-slot="{ row }">
            <span>{{ row.groupLabelPrefix || "-" }}</span>
          </template></el-table-column
        >
        <el-table-column
          label="标签组类别"
          prop="groupLabelType"
          align="center"
        >
          <template v-slot="{ row }">
            <span>{{ row.groupLabelType || "-" }}</span>
          </template></el-table-column
        >

        <el-table-column label="标签组描述" align="center">
          <template v-slot="{ row }">
            <el-popover
              placement="top-start"
              title="标签组描述"
              width="200"
              trigger="hover"
              :content="row.groupLabelDescribe"
            >
              <el-button type="text" slot="reference">{{
                row.groupLabelDescribe || "-"
              }}</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 新增应用模块 -->
        <!-- <el-table-column label="应用模块" align="center" width="160">
          <template v-slot="{ row }">
            <div style="cursor: pointer">
              <div v-if="row.applicationMoudules">
                <el-popover
                  placement="right"
                  popper-class="popperScollStyle"
                  trigger="hover"
                >
                  <div class="content">
                    {{ row.applicationMoudules.join("、") }}
                  </div>
                  <div slot="reference">
                    {{ row.applicationMoudules.join("、") }}
                    <span v-show="row.applicationMoudules.length > 20"
                      >...</span
                    >
                  </div>
                </el-popover>
              </div>
              <span v-else>-</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column label="上传人" prop="userName" align="center">
          <template v-slot="{ row }">
            <span>{{ row.userName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="上传时间"
          sortable
          prop="createTime"
          align="center"
        >
          <template v-slot="{ row }">
            <p>{{ splitTime1(row.createTime) }}</p>
            <p>{{ splitTime2(row.createTime) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="更新人" prop="updateName" align="center">
          <template v-slot="{ row }">
            <span>{{ row.updateName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          sortable
          prop="updateTime"
          align="center"
        >
          <template v-slot="{ row }">
            <p>{{ splitTime1(row.updateTime) }}</p>
            <p>{{ splitTime2(row.updateTime) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" align="center">
          <template v-slot="{ row }">
            <span class="edit" @click="editclick(row)">编辑</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { labelPage, getGroupLabel } from "@/api/label";
import { pagination } from "@/mixins/pagination";
const defaultQueryInfo = Object.freeze({
  groupLabelLevel: null,
  groupLabelType: "",
  labelName: null,
  labelType: null,
  orderByParam: "l.create_time DESC",
  pageNum: 1,
  pageSize: 10,
  queryConditions: null
});
export default {
  name: "labelmanage",
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      grouplabel: [],

      // -----------
      test:
        "智参企业、智选企业、人才数据、产品数据、研究报告、数智活动、政策数据、案例数据、新闻资讯、专家数据、招聘数据",
      cities: [
        {
          label: "智参企业",
          id: "1"
        },
        {
          label: "智选企业",
          id: "2"
        },
        {
          label: "人才数据",
          id: "3"
        },
        {
          label: "产品数据",
          id: "4"
        },
        {
          label: "研究报告",
          id: "5"
        },
        {
          label: "数智活动",
          id: "6"
        },
        {
          label: "政策数据",
          id: "7"
        },
        {
          label: "案例数据",
          id: "8"
        },
        {
          label: "新闻资讯",
          id: "9"
        },
        {
          label: "专家数据",
          id: "10"
        },
        {
          label: "招聘数据",
          id: "11"
        }
      ]
    };
  },
  created() {
    this.search();
    this.getGroupLabel();
  },
  methods: {
    //应用模块展示
    spliceModule1(str) {
      let newStr = str.split("、").splice(0, 2) + "";
      let reStr = newStr.replace(/[\[\]""]/g, "");
      console.log(str, "str");
      console.log(reStr, "reStr");
      return reStr.replace(/1,/g, "智参企业、");
      return reStr.replace(/2,/g, "智选企业、");
      return reStr.replace(/3,/g, "人才数据、");
      return reStr.replace(/4,/g, "产品数据、");
      return reStr.replace(/5,/g, "研究报告、");
      // return reStr.replace(/,/g, "、");
    },
    spliceModule2(str) {
      let newStr = str.split("、").splice(2, 2) + "";
      let reStr = newStr.replace(/[\[\]""]/g, "");
      return reStr.replace(/,/g, "、");
    },
    spliceModule(str) {
      let newStr = str.split("、") + "";
      let reStr = newStr.replace(/[\[\]""]/g, "");
      return reStr.replace(/,/g, "、");
    },
    //切割时间
    splitTime1(time) {
      if (!time) {
        return "";
      }
      return time.split(" ")[0];
    },
    splitTime2(time) {
      if (!time) {
        return "";
      }
      return time.split(" ")[1];
    },
    //查询一览数据
    async search() {
      this.loading = true;
      const res = await labelPage(this.queryInfo);
      if (res.code === 200) {
        this.listData = res.data.list;
        this.total = res.data.total;
        this.loading = false;
      }
    },
    //查询所有标签组类别
    async getGroupLabel() {
      let list = [];
      const res = await getGroupLabel();
      if (res.code == 200) {
        list = res.data;
        list.forEach(el => {
          this.grouplabel.push({
            labeltype: el
          });
        });
      }
    },
    //新增标签
    addlabel() {
      this.$router.push({
        name: "addlabel"
      });
    },
    //编辑
    editclick(row) {
      this.$router.push({
        name: "addlabel",
        query: {
          id: row.id
        }
      });
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == "createTime") {
        if (val.order == "ascending") {
          //updateAt列升序时

          this.queryInfo.orderByParam = "l.create_time ASC";
          this.search();
        } else if (val.order == "descending") {
          //updateAt列降序时

          this.queryInfo.orderByParam = "l.create_time DESC";
          this.search();
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = "l.create_time DESC";
          this.search();
        }
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.search();
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val;
      this.search();
      // console.log(`当前页: ${val}`)
    }
  }
};
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;

  .input-with-select {
    width: 400px;
    float: right;
  }

  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}
/deep/.el-table .cell {
  overflow: inherit;
}
.hid {
  position: absolute;
  bottom: -90px;
  left: 0;
  z-index: 999;
  background: #fff;
  width: 356px;
  height: 86px;
  box-shadow: 0px 0px 4px 0px #ccc;
  border-radius: 2px;
  padding: 10px;
  box-sizing: border-box;
  display: none;
}
.hid:hover {
  display: block;
}
//表格列表el-popover 内容滚动条
.popperScollStyle {
  .content {
    max-height: 300px;
    max-width: 300px;
    line-height: 23px;
    padding-bottom: 0;
    color: #000000;
    overflow-y: auto;
    padding-right: 4px;
  }
}
</style>
